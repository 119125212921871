var API_URL = "api/";
if(process.env.NODE_ENV == 'development'){
	API_URL = "api/api/";
}else{
	API_URL = "api/";
}
import $ from 'jquery'
import CM from './common.js'
export default {
    JQajax: function(options){
      let authKey = "";
      let lgdata = localStorage.getItem('loginData')
      let tkcookie = localStorage.getItem('loginData');
      if(lgdata){
        authKey = JSON.parse(lgdata).token_type+" "+JSON.parse(lgdata).access_token;
      }else {
        authKey = 'Basic d2ViQXBwOndlYkFwcA==';
      }
      // console.log("token"+authKey);
      let nTime=Date.parse( new Date())/1000;
      let tvm="";
      let	that = this,
      url = '',
      method = 'POST',
      token=authKey,
      contentT='application/x-www-form-urlencoded',
      data = options.data;
          for(let Key in options){
              switch (Key){
                  case 'url':
                  url = options[Key];
                  break;
                  case 'type':
                  case 'method':
                  method = options[Key];
                  break;
                  case 'data':
                  data = options[Key];
                  break;
                  case 'token':
                  token = options[Key];
                  break;
                  case 'contentT':
                  contentT = options[Key];
                  break;
                  case 'success':
                  var successfn = options[Key];
                  break;
                  case 'error':
                  case 'fail':
                  var errorfn = options[Key];
                  break;
                  case 'tvm':
                  tvm = options[Key];
                  break;
              }
          }
          // if(loginData){
            if(lgdata && (nTime-JSON.parse(lgdata).timestamp1)>10800){
              let refresh=JSON.parse(lgdata).refreshToken;
              // console.log("refresh token",refresh);
              $.ajax({
                url :API_URL + 'login/adminRefreshToken',
                type: "POST",
                contentType:"application/json",
                data:JSON.stringify({
                  "grant_type":"refresh_token",
									"scope":"admin_hotel",
		              "client_id":"admin_hotel",
		              "client_secret":"admin_hotel",
                  "refresh_token":refresh
                }),
                // beforeSend: function(xhr) {
                //   xhr.setRequestHeader("Authorization", "Basic d2ViQXBwOndlYkFwcA==");
                // },
                success: function(res) {
                  let obj={
                    access_token:res.access_token,
                    usename:res.usename,
                    token_type:res.token_type,
                    orgType:res.orgType,
                    timestamp1:Date.parse( new Date())/1000,
                    refreshToken:res.refresh_token
                  }
                  // CM.setCookie("access_token",res.access_token,2,"");
                  // CM.setCookie("usename",res.usename,2,"");
                  // CM.setCookie("token_type",res.token_type,2,"");
                  // CM.setCookie("timestamp1",Date.parse( new Date())/1000,2,"");
                  // CM.setCookie("refreshToken",res.refresh_token,2,"");
                  localStorage.setItem('loginData', JSON.stringify(obj));
                  // let loginData=JSON.parse(localStorage.getItem("loginData"));
                  setTimeout(function(){
                    // console.log("刷新的token",res.token_type+" "+res.access_token);
                    $.ajax({
                      url : url,
                      type: method,
                      contentType: contentT,
                      data:data,
                      beforeSend: function(xhr) {
                        xhr.setRequestHeader("Authorization", res.token_type+" "+res.access_token);
                      },
                      success: function(data) {
                        if(that.isString(data)){
                            try {
                                var oData = JSON.parse(data);
                            }
                            catch(e) {
                                oData = data;
                            }
                        }else{
                            oData = data;
                        }
                        that.isFunction(successfn) && successfn(oData);
                        return;
                      },

                      error:function(data){
                        if(res.status==401){
                          localStorage.removeItem('loginData');
                          localStorage.removeItem('name');
                          localStorage.removeItem('menu');
                          localStorage.removeItem('hotelmenu');
                          // document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=";
													tvm.$router.push('/login');
                          return;
                        }
                          that.isFunction(errorfn) && errorfn(data);
                          return;
                      }
                    });
                  },200)
                },

                error:function(){
                    localStorage.removeItem('loginData');
                    localStorage.removeItem('name');
                    localStorage.removeItem('menu');
                    localStorage.removeItem('hotelmenu');
                    // document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=";
										tvm.$router.push('/login');
                }
              })
              return;
            }else{
              // console.log("现在token",CM.getCookie("access_token"),token,CM.getCookie("token_type"));
              $.ajax({
                url : url,
                type: method,
                contentType: contentT,
                data:data,
                beforeSend: function(xhr) {
                  xhr.setRequestHeader("Authorization", token);
                },
                success: function(res) {
                  if(that.isString(res)){
                      try {
                          var oData = JSON.parse(res);
                      }
                      catch(e) {
                          oData = res;
                      }
                  }else{
                      oData = res;
                  }
                  that.isFunction(successfn) && successfn(oData);
                },

                error:function(res){
                  if(res.status==401){
                    localStorage.removeItem('loginData');
                    localStorage.removeItem('name');
                    localStorage.removeItem('menu');
                    localStorage.removeItem('hotelmenu');
                    // document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=";
										tvm.$router.push('/login');
                    return;
                  }else if(res.status==403){
                    tvm.$message.error('認証失敗　この権限がありません。もう一度ログインするか、管理者に連絡して処理を依頼してください');
                  }
                  that.isFunction(errorfn) && errorfn(res);
                }
            });
            }
          return false
    },
    isString: function (obj) {
        return Object.prototype.toString.call(obj) === "[object String]";
    },
    setCookie: function (c_name,value,day,domain){
        var exdate=new Date();
        exdate.setTime(exdate.getTime()+day*24*60*60*1000);
        document.cookie=c_name+ "=" +escape(value)+((day==null) ? "" : ";expires="+exdate.toGMTString())+";path=/;domain="+domain;
    },
    getCookie: function (cname){
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for(var i=0; i<ca.length; i++)
      {
        var c = ca[i].trim();
        if (c.indexOf(name)==0) return c.substring(name.length,c.length);
      }
      return "";
    },
    isFunction: function (obj) {
        return Object.prototype.toString.call(obj) === "[object Function]";
    },
    formatNum: function (strNum) {
                if(strNum<1000) {
                    return strNum;
                }
                if(!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
                    return strNum;
                }
                var a = RegExp.$1,
                    b = RegExp.$2,
                    c = RegExp.$3;
                var re = new RegExp();
                re.compile("(\\d)(\\d{3})(,|$)");
                while(re.test(b)) {
                    b = b.replace(re, "$1,$2$3");
                }
                return a + "" + b + "" + c;
            },
    formatMoney:function (num) {
     // num = num.toString().replace(/\,/g,'');
     if(num){
       var sign = (num == (num = Math.abs(num)));
       num = Math.floor(num*100+0.50000000001);
       var cents=Math.abs(num%100),c;
       if(cents==0){
           c=""
       }else if(cents<10){
           c=".0"+cents
       }else{
           c="."+cents
       }
       num = Math.floor(num/100).toString();
       for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
           num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
       }
       return (((sign)?"":'-') + num + c);
     }else{
       return 0;
     }
 },
 gettypedata:function (typename,lang,shortname,vthat,fn){
   let that=this;
   var typeinfodata =localStorage.getItem(lang+shortname);
	 // console.log("typeinfodata.length",typename,shortname,typeinfodata.length);
   if(typeinfodata && JSON.parse(typeinfodata).length){
     var strdata = JSON.parse(typeinfodata);
     fn(strdata);
   }else{
     that.JQajax({
       url:API_URL+"pms/basedocinfo",
       method:"POST",
       contentT:"application/json",
       data:JSON.stringify({
         "className": typename,
         "lang": lang
       }),
       tvm:vthat,
       success: function(data) {
         if(data.code==200 && data.data.length){
           localStorage.setItem(lang+shortname,JSON.stringify(data.data));
           fn(data.data);
         }else{
           vthat.$message.error(data.msg);
         }
       },
       error:function(data){
         vthat.$message.error(data);
       }
     })
   }
 },
 getpmsdata:function (lang,para,vthat,callback){
   let that=this;
   var pmsdataStr=localStorage.getItem(lang+para);
  //  if(pmsdataStr){
  //    var strdata = JSON.parse(pmsdataStr);
  //    callback(strdata);
  //  }else{
     that.JQajax({
       url:API_URL+"pms/page",
       method:"POST",
       contentT:"application/json",
       data:JSON.stringify({
         "lang": lang,
         "pageCode": para
       }),
       tvm:vthat,
       success: function(data) {
         console.log("请求多语言",data);
         if(data.code==200){
					 if(data.data.pageTemplateDetailDTO){
						 let odata = data.data.pageTemplateDetailDTO;
	           var _pmsTmp = {};
	           odata.forEach(function (obj) {
	             let newkey = obj.pageTemplateDetailEntity.resCatalog+obj.pageTemplateDetailEntity.resID;
	             _pmsTmp[newkey] = obj.pageTemplateDetailEntity.showName;
	           })
	           localStorage.setItem(lang+para,JSON.stringify(_pmsTmp));
	           callback(_pmsTmp);
					 }
         }else{
           vthat.$message.error(data.msg);
         }
       },
       error:function(data){
         vthat.$message.error(data);
       }
     })
  //  }
 },
}
